<template>
  <div class="corneaContact">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "corneaContact",
  created() {
    this.$store.commit('isShow/upBottom', false)
  },
}
</script>

<style scoped>
  .corneaContact {
    width: 100vw;
    height: 87vh;
    overflow: hidden;
    overflow-y: auto;
  }
</style>